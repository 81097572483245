/* global
 autosize
 bsCustomFileInput
 checkRedirect
 initHtmlTextarea
 MultipleSelectItems
 updateHtmlContent
 */

const csrftoken = getCookie("csrftoken");

const headers = new Headers({
  Accept: "application/json",
  "Content-Type": "application/json; charset=UTF-8",
  "X-CSRFToken": csrftoken,
});

// #############################################################################
// FOCUS

$("body").betterFocus({
  selector: "a, [tabindex]",
});


// #############################################################################
// TOOLTIP

$("[data-toggle=tooltip]").tooltip();


// #############################################################################
// FORM: MULTIPLE SELECT ITEMS

const initMultipleSelectItems = () => {
  const $inputs = document.querySelectorAll("[data-multiple-select-item]");

  $inputs.forEach(function ($input) {
    if ($input.$multipleSelectItem) {
      return;
    }

    $input.$multipleSelectItem = new MultipleSelectItems($input);
    $input.$multipleSelectItem.init();
  });
};


// #############################################################################
// FORM

function initFormDefaults ($parent = $("body")) {
  // File
  bsCustomFileInput.init();

  // Autosize
  autosize($("textarea", $parent));

  // HTML TinyMCE
  initHtmlTextarea($parent);

  // Range
  $("[type=range]", $parent).formRange();

  // Ajax upload
  $("[data-ajax-upload]", $parent).ajaxUpload({
    onUploadCompleted: function ($upload, $data) {
      updateHtmlContent($data);
    },
  });

  // Wizard
  $("[data-form=\"wizard\"]", $parent).formWizard();

  // File tree
  $("[data-file-tree]", $parent).formFileTree();

  // Form set

  $("[data-form-set]", $parent).formSet();

  initMultipleSelectItems();
}

initFormDefaults();

// Validation

$("[data-form]").formValidation({
  afterSubmit: function (request, $form, $data) {
    if ($data.submit === "success") {
      checkRedirect($data);
      updateHtmlContent($data);

      if ($data.toaster) {
        $("body").toaster("updateToaster", $data.toaster);
      }
    }
  },
});

// Wizard

$("[data-form-wizard]").formWizard();


// #############################################################################
// AJAX MODAL

$("body").ajaxModal({
  selector: "[data-modal-link]",
  beforeModalOpen: function ($modal, $data) {
    if ($data.submit === "error") {
      if ($data.toaster) {
        $("body").toaster("updateToaster", $data.toaster);
      }
    }
  },
  onModalOpen: function ($modal) {
    $("[autofocus]", $modal).focus();
    $("[data-toggle=tooltip]", $modal).tooltip();

    initFormDefaults($modal);

    // Validation

    $("[data-form]", $modal).formValidation({
      afterSubmit: function (request, $form, $data) {
        if ($data.submit === "success") {
          $modal.modal("hide");
          checkRedirect($data);
          updateHtmlContent($data);

          if ($data.toaster) {
            $("body").toaster("updateToaster", $data.toaster);
          }

          $("[data-table]").xDataTable("reload");
        }
      },
    });

    // Wizard

    $("[data-form-wizard]", $modal).formWizard();
  },
});


// #############################################################################
// DATA TABLE

$("[data-table]").xDataTable({
  options: {
    columns: [
      {
        data: "PLACEHOLDER_FIELDS",
      },
    ],
  },
  onInit: function ($table, $json) {
    // Custom code on init
    localStorage.clear();
  },
  onStateLoaded: function ($table, $data) {
    // Custom code on init
  },
  onDraw: function ($table) {
    // Custom code on redraw

    const $input = $table[0].querySelector("[data-multiple-select-item]");

    if ($input) {
      $input.$multipleSelectItem.reset();
    }

    $("[data-toggle=tooltip]", $table).tooltip();
  },
});


// #############################################################################
// DOWNLOAD BLOB

$("[data-download]").downloadBlob({
  onDownloadStarted: function ($data) {
    $("body").toaster("updateToaster", $data.toaster);
  },
});


// #############################################################################
// CLIPBOARD

$("body").clipBoard({
  selector: "[data-clipboard]",
});


// #############################################################################
// TOASTER

$("body").toaster({
  selector: "[data-toaster]",
});


// #############################################################################
// AUTO UPDATE HTML CONTENT

$("body").autoUpdateHtmlContent({
  selector: "[data-update-html-content]",
});

function getCookie (name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + "=")) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

$(document).delegate(".change-website-type-select", "change", function (ev) {
  const website_id = this.parentElement.parentElement.getAttribute("id").replace("row-", "");
  const body = { new_website_type_id: this.value };
  updateWebsite(website_id, body).then((result) => {
    if (result.success === false) {
      // sets the option back when changing gone wrong
      if (result.old_website !== undefined) {
        if (result.old_website === null) {
          $(this).val("-");
        } else {
          $(this).val(result.old_website.id);
        }
      }
    } else {
      const all_related_childs = this.parentElement.parentElement.childNodes;

      for (const related_child of all_related_childs) {
        if (related_child.classList.contains("modified-at")) {
          $(related_child).html(result.modified_at_template);
        }

        if (related_child.classList.contains("is-edited")) {
          if (result.is_edited) {
            $(".icon-sm", related_child)[0].setAttribute("fill", "green");
          } else {
            $(".icon-sm", related_child)[0].setAttribute("fill", "red");
          }
        }
      }
    }
  });
});

$(document).delegate(".was_viewed", "click", function () {
  const website_id = this.parentElement.parentElement.parentElement.getAttribute("id").replace("row-", "");
  const body = { website_edited: "change" };
  updateWebsite(website_id, body).then((result) => {
    const all_related_childs = this.parentElement.parentElement.parentElement.childNodes;
    for (const related_child of all_related_childs) {
      if (related_child.classList.contains("modified-at")) {
        $(related_child).html(result.modified_at_template);
      }
    }

    if (result.is_edited) {
      this.childNodes[1].setAttribute("fill", "green");
    } else {
      this.childNodes[1].setAttribute("fill", "red");
    }
  });
});

async function updateWebsite (website_id, body) {
  return await fetch(`/update/website/type/${website_id}`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: headers,
  }).then(r => r.json())
    .then(result => {
      $("body").toaster("updateToaster", `${result.toaster}`);
      return result;
    });
}

$(document).delegate(".information-icon", "mouseenter", function () {
  const website_id = this.getAttribute("data-information-id");
  const logging_container = $(`.logging-container-id-${website_id}`);
  $(logging_container).html("<div class='d-flex justify-content-center align-items-center p-2'>" +
    "<span id=\"logging-loader\" class=\"align-middle d-flex flex-column align-items-center\">\n" +
    "      <span class=\"processing-loader\" data-processing></span>\n" +
    "      <span data-processing style='white-space: nowrap'>Bitte warten...</span>\n" +
    "    </span>" +
    "</div>");

  if (window.innerWidth > 1526) {
    logging_container[0].style.right = "25px";
  }

  fetch(`/logging/${website_id}`)
    .then(response => response.json())
    .then(result => {
      if ("template" in result) {
        $(logging_container).html(result.template);
      }
    });
});

$(document).delegate(".import-csv", "click", function () {
  const csvId = this.getAttribute("data-id");
  const removeBtn = document.querySelector(`.remove-import-csv-${csvId}`);

  lastUsedImportButtons.push(this);

  removeBtn.classList.add("disabled");
  this.classList.add("disabled");

  disableAllImportButtons();

  fetch("/csv/overview", {
    method: "POST",
    body: JSON.stringify({ csv_id: csvId }),
    headers: headers,
  })
    .then(response => response.json())
    .then(result => {
      $("body").toaster("updateToaster", `${result.toaster}`);
      return result;
    });
  checkProgress(csvId, removeBtn);
});

function checkProgress (csvId, removeBtn) {
  const progressBar = document.querySelector(`.progress-bar-${csvId}`);
  $.ajax({
    url: `/progress/csv/${csvId}`,
    method: "GET",
    success: function (data) {
      if (data.progress <= 100) {
        progressBar.style.width = `${data.progress}%`;
        progressBar.innerHTML = `${data.progress}%`;
        if (data.progress !== 100 && data.status !== 3) {
          setTimeout(() => {
            checkProgress(csvId, removeBtn);
          }, 1500);
        }

        if (data.status === 3) {
          const warningIcon = document.querySelector(`.icon-${csvId}`);
          const textToChange = document.querySelector(`.text-change-${csvId}`);
          warningIcon.classList.remove("d-none");
          textToChange.classList.add("text-danger");
        }
      }

      if (data.progress === 100 && data.status !== 1) {
        removeBtn.classList.remove("disabled");
        enableAllNecessaryImportButtons();
        const successIcon = document.querySelector(`.icon-success-${csvId}`);
        successIcon.classList.remove("d-none");
        $(`.analyze-box-${csvId}`)[0].innerHTML = "<div class=\"d-flex align-items-center\">\n" +
          "          <span>Sende URL's zur KI</span>\n" +
          "          <div class=\"lds-ellipsis\"><div></div><div></div><div></div><div></div></div>\n" +
          "          </div>";
        setTimeout(() => {
          checkProgress(csvId, removeBtn);
        }, 3500);
      }

      if (data.status === 1) {
        $(`.analyze-box-${csvId}`)[0].innerHTML = "<span>URL's zur KI gesendet</span>";
      }
    },
  });
}

const lastUsedImportButtons = [];

function disableAllImportButtons () {
  const allImportButtons = document.querySelectorAll(".import-csv");

  for (const importButton of allImportButtons) {
    importButton.classList.add("disabled");
  }
}

function enableAllNecessaryImportButtons () {
  const allImportButtons = document.querySelectorAll(".import-csv");

  for (const importButton of allImportButtons) {
    if (!(lastUsedImportButtons.includes(importButton) || importButton.hasAttribute("data-imported"))) {
      importButton.classList.remove("disabled");
    }
  }
}

window.addEventListener("DOMContentLoaded", function () {
  const importLines = document.querySelectorAll(".import-csv");

  for (const line of importLines) {
    const csvID = line.getAttribute("data-id");
    $.ajax({
      method: "GET",
      url: `/has/progress/csv/${csvID}`,
      success: function (data) {
        if (data.status[1] === "RUNNING") {
          checkProgress(csvID, document.querySelector(`.remove-import-csv-${csvID}`));
        }
      },
    });
  }
});


// EXPORT DATATABLE CSV
// ###########################################################

$("#export-datatable-csv").on("click", async function () {
  const elementsToHandle = $("[id*='row-']");
  const websiteIds = [];

  for (const element of elementsToHandle) {
    websiteIds.push(element.getAttribute("id").replace("row-", ""));
  }

  await fetch("/export/datatable/data", {
    method: "POST",
    headers: headers,
    body: JSON.stringify(websiteIds),
  })
    .then(response => {
      // Check if the Content-Disposition header is present
      const contentDisposition = response.headers.get("Content-Disposition");
      // Extract filename from the Content-Disposition header, if available
      let filename = "default_filename.csv"; // Default filename if not found
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+?)"/);
        if (match) {
          filename = match[1];
        }
      }
      // Convert the CSV data to a Blob
      return response.text().then(csvData => ({ csvData, filename }));
    })
    .then(({ csvData, filename }) => {
      const blob = new Blob([csvData], { type: "text/csv" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(error => console.error("Error:", error));
});
